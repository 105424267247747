import axios from "@/axios"
import serializer from "@/api/serializer"

import type StatisticResource from "@/models/StatisticResource"
type curResourceProperties = ResourcePropertiesOf<StatisticResource>

export default abstract class StatisticsApi<T extends curResourceProperties> {
  namespace = ""
  type = ""
  serializer = serializer
  axios = axios

  apiPath({ scope, format }: { scope?: string; format?: string } = {}) {
    const base = scope ? `${this.namespace}/${scope}` : this.namespace

    if (!format) {
      return base
    }

    return `${base}.${format}`
  }

  async get({ filter }: { filter?: { [x: string]: unknown } } = {}): Promise<
    APIGetResponse<T>
  > {
    const res = await this.axios.get(this.apiPath(), {
      params: { filter },
    })

    return {
      data: this.serializer.deserialize(res.data) as T,
      meta: res.data.meta,
    }
  }
}
