import ResourceApi from "./resource_api"

import type FormInput from "@/models/FormInput"
type curResourceProperties = ResourcePropertiesOf<FormInput>

export default class FormApi extends ResourceApi<curResourceProperties> {
  namespace = "form_inputs"
  type = "form_input"

  async createForPurpose(purpose: string, opts: { domainId?: string } = {}) {
    const res = await this.axios.post(
      this.apiPath({ scope: "create_for_purpose" }),
      {
        data: { purpose, domain_id: opts.domainId },
      },
    )

    return this.serializer.deserialize(res.data) as curResourceProperties
  }
}
