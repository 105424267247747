import ResourceApi from "./resource_api"
import { serialize as objectToFormData } from "object-to-formdata"

import type Event from "@/models/Event"
import EventAppointment from "@/models/EventAppointment"
import type EventApplication from "@/models/EventApplication"

type curResourceProperties = ResourcePropertiesOf<Event>
type EventApplicationResourceProperties = ResourcePropertiesOf<EventApplication>

export default class EventApi extends ResourceApi<curResourceProperties> {
  namespace = "events"
  type = "event"

  async publish(event: curResourceProperties): Promise<curResourceProperties> {
    const res = await this.axios.put(
      this.apiPath({ scope: `${event.id}/publish` }),
      {
        id: event.id,
        type: event.type,
      },
    )

    return this.serializer.deserialize(res.data) as curResourceProperties
  }

  async archive(id: string): Promise<curResourceProperties> {
    const res = await this.axios.put(this.apiPath({ scope: `${id}/archive` }), {
      id,
      type: this.type,
    })

    return this.serializer.deserialize(res.data) as curResourceProperties
  }

  async cancel(id: string): Promise<curResourceProperties> {
    const res = await this.axios.put(this.apiPath({ scope: `${id}/cancel` }), {
      id,
      type: this.type,
    })

    return this.serializer.deserialize(res.data) as curResourceProperties
  }

  async uncancel(id: string): Promise<curResourceProperties> {
    const res = await this.axios.put(
      this.apiPath({ scope: `${id}/uncancel` }),
      {
        id,
        type: this.type,
      },
    )

    return this.serializer.deserialize(res.data) as curResourceProperties
  }

  async resetInvoicePdfLayout(id: string) {
    await this.axios.put(
      this.apiPath({ scope: `${id}/reset_invoice_pdf_layout` }),
      {
        id,
        type: this.type,
      },
    )

    return true
  }

  async apply(
    event: curResourceProperties,
  ): Promise<EventApplicationResourceProperties> {
    const res = await this.axios.post(
      this.apiPath({ scope: `${event.id}/apply` }),
      {
        id: event.id,
        type: event.type,
      },
    )

    return this.serializer.deserialize(
      res.data,
    ) as EventApplicationResourceProperties
  }

  async applications(
    id: string,
    page?: number,
    size?: number,
  ): Promise<APIAllResponse<EventApplicationResourceProperties[]>> {
    const res = await this.axios.get(
      this.apiPath({ scope: `${id}/applications` }),
      {
        params: {
          page: {
            number: page || 1,
            size: size || 20,
          },
        },
      },
    )

    return {
      data: this.serializer.deserialize(
        res.data,
      ) as EventApplicationResourceProperties[],
      meta: res.data.meta,
    }
  }

  async createAppointments(
    id: string,
    appointments: Array<Partial<ResourcePropertiesOf<EventAppointment>>>,
  ) {
    await Promise.all(
      appointments.map((appointment) => {
        return EventAppointment.api.create({
          attributes: appointment,
          relationshipPath: [{ name: "event", id }, { name: "appointments" }],
        })
      }),
    )

    return await this.get(id, {
      collectionParams: { include: ["appointments"] },
    })
  }

  async deleteAppointments(id: string, deleteIds: string[]) {
    await Promise.all(
      deleteIds.map((appointmentId) => {
        return EventAppointment.api.delete(appointmentId, {
          relationshipPath: [{ name: "event", id }, { name: "appointments" }],
        })
      }),
    )

    return await this.get(id, {
      collectionParams: { include: ["appointments"] },
    })
  }

  async sendMail(
    id: string,
    subject: string,
    body: string,
    {
      attachments,
      domain,
      language,
      salutationStyleId,
      filter,
      scopeFilter,
      sendAt,
      tagsToAdd,
      preview,
      previewType,
      withInvoice,
      withIcal,
    }: {
      attachments?: FileResource[]
      domain?: string
      language?: string
      salutationStyleId?: string
      filter?: { [x: string]: unknown }
      scopeFilter?: { [x: string]: unknown }
      sendAt?: string
      tagsToAdd?: string[]
      preview?: boolean
      previewType?: "mail" | "html"
      withInvoice?: boolean
      withIcal?: boolean
    } = {},
  ) {
    const path = this.apiPath({
      scope: preview ? `${id}/send_mail_preview` : `${id}/send_mail`,
    })

    const data = {
      domain_id: domain,
      only_html: preview && previewType == "html" ? true : undefined,
      data: {
        subject,
        body,
        language,
        send_at: sendAt,
        salutation_style_id: salutationStyleId,
        attachments: undefined as undefined | File[],
        recipient_tags_to_add_ids: tagsToAdd,
        with_invoice: withInvoice,
        with_ical: withIcal,
      },
    }

    if (attachments) {
      const presentAttachments: File[] = attachments
        .filter((attachment) => attachment.file || attachment.id)
        .map((attachment) => (attachment.file as File) || attachment.id)

      if (presentAttachments.length > 0) {
        data.data.attachments = presentAttachments
      }
    }

    const post = this.axios.post(path, objectToFormData(data), {
      params: { filter, scope_filter: scopeFilter },
    })

    if (!preview || previewType != "html") {
      return post
    }

    return (await post).data
  }

  async sendMailTemplatePreview(
    id: string,
    mailTemplateId: string,
    onlyHtml = false,
  ) {
    const path = this.apiPath({
      scope: `${id}/send_mail_template_preview/${mailTemplateId}`,
    })

    const ret = this.axios.put(path, { only_html: onlyHtml })

    if (onlyHtml) {
      return (await ret).data
    } else {
      return ret
    }
  }

  async addAccountUsers(
    id: string,
    filter: unknown,
    { state, sendMail }: { state?: string; sendMail?: boolean } = {},
  ) {
    const path = this.apiPath({ scope: `${id}/add_account_users` })

    return this.axios.post(path, {
      data: { filter, state, send_email: sendMail },
    })
  }

  async createManualApplication(id: string, email: string) {
    const path = this.apiPath({ scope: `${id}/create_manual_application` })

    const ret = await this.axios.post(path, { data: { email } })

    return this.serializer.deserialize(
      ret.data,
    ) as EventApplicationResourceProperties
  }

  importUsers(id: string, file: FileResource) {
    const path = this.apiPath({ scope: `${id}/import_users` })

    return this.axios.post(path, objectToFormData({ data: { file } }))
  }

  async downloadCertificatePreview(
    id: string,
    { filename }: { filename?: string } = {},
  ): Promise<void> {
    const path = this.apiPath({ scope: `${id}/certificate_preview` })

    const res = await this.axios.post(path, {}, { responseType: "blob" })

    this.downloadBlob(
      res.data,
      filename || res.headers["x-filename"] || "certificate_preview.pdf",
    )
  }

  async downloadInvoicePreview(
    id: string,
    { filename }: { filename?: string } = {},
  ): Promise<void> {
    const path = this.apiPath({ scope: `${id}/invoice_pdf_preview` })

    const res = await this.axios.post(path, {}, { responseType: "blob" })

    this.downloadBlob(
      res.data,
      filename || res.headers["x-filename"] || "invoice_preview.pdf",
    )
  }

  async getBySlug(
    slug: string,
    {
      collectionParams,
      query,
    }: { collectionParams?: APIResourceCollectionParams; query?: {} } = {},
  ): Promise<APIGetResponse<curResourceProperties>> {
    const params = (query && { ...query }) || {}
    if (collectionParams) {
      this.addCollectionParamsToObject(collectionParams, params)
    }

    const path = this.apiPath({ scope: `by_slug/${slug}` })
    const res = await this.axios.get(path, {
      params: { ...params },
    })

    return {
      data: this.serializer.deserialize(res.data) as curResourceProperties,
      meta: res.data.meta,
    }
  }

  async interpolationDoc(
    id?: string,
    opts: {
      withApplication?: boolean
      withEventSeries?: boolean
      withUser?: boolean
      withGlobal?: boolean
      withSignature?: boolean
      withCustom?: string[]
    } = {},
  ) {
    const path = this.apiPath({
      scope: id ? `${id}/interpolation_doc` : "interpolation_doc",
    })

    const params = {} as { [x: string]: unknown }
    params.with_application = opts.withApplication
    params.with_global = opts.withGlobal
    params.with_signature = opts.withSignature
    params.with_user = opts.withUser
    params.with_event_series = opts.withEventSeries
    params.with_custom = opts.withCustom

    return (await this.axios.get(path, { params })).data?.data
  }
}
