import ApplicationResource from "./ApplicationResource"
import type MailPicture from "@/models/MailPicture"
import type NewsletterSectionView from "@/models/NewsletterSectionView"

export default class NewsletterView extends ApplicationResource {
  static readonly fileResources: string[] = ["imprint_picture"]

  declare ending?: string
  declare foreword?: string
  declare imprint_picture?: FileResource
  declare imprint_picture_location?: string
  declare title?: string

  static readonly associations = {
    banner_mail_picture: {
      type: "single",
    },
    ending_mail_picture: {
      type: "single",
    },
    foreword_mail_picture: {
      type: "single",
    },
    newsletter_sections: {
      type: "many",
    },
  }

  declare banner_mail_picture?: MailPicture
  declare ending_mail_picture?: MailPicture
  declare foreword_mail_picture?: MailPicture
  declare newsletter_sections: NewsletterSectionView[]
}
