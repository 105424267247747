import ApplicationResource from "./ApplicationResource"
import type Form from "@/models/Form"
import type MailPicture from "@/models/MailPicture"
import type SmtpConfig from "@/models/SmtpConfig"
import type SmtpEmailAddress from "@/models/SmtpEmailAddress"

import SignatureApi from "@/api/signature_api"

export const API = new SignatureApi()

export default class Signature extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  static readonly fileResources: string[] = ["imprint_picture"]

  declare complementary_close_de?: string
  declare complementary_close_en?: string
  declare default?: boolean
  declare imprint_de?: string
  declare imprint_en?: string
  declare imprint_picture?: FileResource
  declare imprint_picture_location?: string
  declare name_de?: string
  declare name_en?: string

  static readonly associations = {
    banner_mail_picture: {
      type: "single",
    },
    form: {
      type: "single",
    },
    smtp_config: {
      type: "single",
    },
    smtp_email_address: {
      type: "single",
    },
  }

  declare banner_mail_picture?: MailPicture
  declare form?: Form
  declare smtp_config?: SmtpConfig
  declare smtp_email_address?: SmtpEmailAddress

  displayLabel() {
    return this.getLocalized("name") || this.id
  }
}
