import ApplicationResource from "./ApplicationResource"
import type Form from "@/models/Form"
import type MailPicture from "@/models/MailPicture"
import type NewsletterSchedule from "@/models/NewsletterSchedule"
import type NewsletterSection from "@/models/NewsletterSection"
import type NewsletterStatistic from "@/models/NewsletterStatistic"
import type Search from "@/models/Search"
import type Signature from "@/models/Signature"

import Api from "@/api/newsletter_api"

type NewsletterAppliedPermissions = ResourceAppliedPermission | "duplicate"

const API = new Api()

export default class Newsletter extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  declare meta: ApplicationResource["meta"] & {
    applied_permissions?: NewsletterAppliedPermissions[]
  }

  declare locales?: string[]
  declare main_locale?: string
  declare state?: string
  declare state_de?: string
  declare state_en?: string
  declare title_de?: string
  declare title_en?: string

  static readonly associations = {
    banner_mail_picture: {
      type: "single",
    },
    ending_mail_picture: {
      type: "single",
    },
    foreword_mail_picture: {
      type: "single",
    },
    form: {
      type: "single",
    },
    newsletter_schedule: {
      type: "single",
    },
    newsletter_sections: {
      type: "many",
    },
    recipients_search: {
      type: "single",
    },
    signature: {
      type: "single",
    },
    statistic: {
      type: "single",
    },
  }

  declare banner_mail_picture?: MailPicture
  declare ending_mail_picture?: MailPicture
  declare foreword_mail_picture?: MailPicture
  declare form?: Form
  declare newsletter_schedule?: NewsletterSchedule
  declare newsletter_sections: NewsletterSection[]
  declare recipients_search?: Search
  declare signature?: Signature
  declare statistic?: NewsletterStatistic

  fallbackLocale(): string {
    return this.main_locale || ""
  }

  availableLocales(): string[] {
    return this.locales || []
  }
}
