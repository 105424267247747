import axios from "@/axios"

export default {
  async get({
    locales,
    keys,
    lookupScope,
  }: {
    locales?: string[]
    keys?: string[]
    lookupScope?: "default" | "custom" | "fallback"
  } = {}): Promise<{ [x: string]: any }> {
    const res = await axios.get("translations", {
      params: { locales, keys, scope: lookupScope },
    })
    return res.data
  },
  async update(translations: {}) {
    const res = await axios.put("translations", {
      ...translations,
    })
    return res.data
  },
  remove(keys: string[]) {
    axios.delete("translations", { data: { keys } })
  },
}
