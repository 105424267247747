import ResourceApi from "./resource_api"

import type PdfLayout from "@/models/PdfLayout"
type curResourceProperties = ResourcePropertiesOf<PdfLayout>

export default class PdfLayoutApi extends ResourceApi<curResourceProperties> {
  namespace = "pdf_layouts"
  type = "pdf_layout"

  async createForPurpose(
    data: Record<string, unknown>,
    opts: { domainId?: string } = {},
  ) {
    const res = await this.axios.post(
      this.apiPath({ scope: "create_for_purpose" }),
      {
        data: Object.assign(data, { domain_id: opts.domainId }),
        domain_id: opts.domainId,
      },
    )

    return this.serializer.deserialize(res.data) as curResourceProperties
  }

  async preview(
    id: string,
    { filename }: { filename?: string } = {},
  ): Promise<void> {
    const path = this.apiPath({ scope: `${id}/preview` })

    const res = await this.axios.get(path, { responseType: "blob" })

    this.downloadBlob(
      res.data,
      filename || res.headers["x-filename"] || "preview.pdf",
    )
  }
}
