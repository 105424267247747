import Vue from "vue"

import Router from "vue-router"
Vue.use(Router)

import qs from "qs"

import config from "@/config"
import { useStore } from "@/store"

import { setLocale, locales } from "@/i18n"
import { $settings } from "./plugins/settings"
import { $login } from "./plugins/auth"

const loggedIn = () => {
  return $login.value && !$login.value.isGuest()
}

export const useRouter = () => {
  const $store = useStore()

  const eventSlugMeta =
    $settings.value.public_events?.slug_path_name == "event_apply"
      ? {
          title: ["SideBar.events", "$site", ".registration"],
          onlyPublicSidebar: true,
          localePath: false,
        }
      : {
          title: ["SideBar.events", "$site"],
          onlyPublicSidebar: true,
          localePath: false,
        }

  const router = new Router({
    mode: "history",
    base: config.base_url,
    parseQuery(query) {
      return qs.parse(query)
    },
    stringifyQuery(query) {
      const result = qs.stringify(query)
      return result ? `?${result}` : ""
    },
    scrollBehavior(to, from, _savedPosition) {
      if (
        (from?.name == to?.name && from?.hash == to?.hash) ||
        (from?.name != to?.name && !to?.hash)
      ) {
        return
      }

      let contentEl: HTMLElement | undefined = undefined
      let offset = 0

      if (document.body.clientWidth > 900) {
        contentEl = document.getElementById("content") as HTMLElement
        offset = contentEl.getBoundingClientRect().top * -1
      } else {
        contentEl = document.documentElement as HTMLElement
      }

      let scrollTop = 0

      if (to.hash?.length > 1) {
        const toElement = document.getElementById(to.hash.substring(1)) as
          | HTMLElement
          | undefined

        if (toElement) {
          scrollTop = toElement.offsetTop + offset
        }
      }

      contentEl.scrollTop = scrollTop
    },
    routes: [
      {
        path: "/p/:slug",
        name: "poll_slug_redirect",
        props: true,
        component: () => import("@/views/PollSlugRedirectView.vue"),
      },
      {
        path: `/:locale([a-z]{2})/${$settings.value.router_slugs!.events}`,
        name: "events",
        props: true,
        component: () => import("@/views/EventsView.vue"),
        meta: { title: ["SideBar.events"], onlyPublicSidebar: true },
      },
      {
        path: `/:locale([a-z]{2})/${$settings.value.router_slugs!.events}/:id`,
        name: "event",
        props: true,
        component: () => import("@/views/EventView.vue"),
        meta: { title: ["SideBar.events", "$site"], onlyPublicSidebar: true },
      },
      {
        path: "/e/:slug",
        name: "event_slug",
        props: true,
        component: () => {
          return $settings.value.public_events?.slug_path_name == "event_apply"
            ? import("@/views/EventApplyView.vue")
            : import("@/views/EventView.vue")
        },
        meta: eventSlugMeta,
      },
      {
        path: `/:locale([a-z]{2})/${
          $settings.value.router_slugs!.events
        }/:id/apply`,
        name: "event_apply",
        props: true,
        component: () => import("@/views/EventApplyView.vue"),
        meta: {
          title: ["SideBar.events", "$site", ".registration"],
          onlyPublicSidebar: true,
        },
      },
      {
        path: "/:locale([a-z]{2})/event_applications/:id",
        name: "event_application",
        props: true,
        component: () => import("@/views/EventApplicationView.vue"),
        meta: { onlyPublicSidebar: true },
      },
      {
        path: "/:locale([a-z]{2})/event_series",
        name: "event_series_index",
        props: true,
        component: () => import("@/views/EventSeriesIndexView.vue"),
        meta: { title: ["SideBar.eventSeries"], onlyPublicSidebar: true },
      },
      {
        path: "/:locale([a-z]{2})/event_series/:id",
        name: "event_series",
        props: true,
        component: () => import("@/views/EventSeriesView.vue"),
        meta: {
          title: ["SideBar.eventSeries", "$site"],
          onlyPublicSidebar: true,
        },
      },
      {
        path: "/:locale([a-z]{2})/polls",
        name: "polls",
        props: true,
        component: () => import("@/views/PollsView.vue"),
        meta: { title: ["SideBar.polls"], onlyPublicSidebar: true },
      },
      {
        path: "/:locale([a-z]{2})/polls/:id",
        name: "poll",
        props: true,
        component: () => import("@/views/PollView.vue"),
        meta: { title: ["SideBar.polls", "$site"], onlyPublicSidebar: true },
      },
      {
        path: "/:locale([a-z]{2})/newsletters/:accessToken",
        name: "newsletter_view",
        props: true,
        component: () => import("@/views/NewsletterView.vue"),
        meta: {
          title: ["$site"],
          sidebar: false,
          topBar: false,
          footer: false,
          poweredBy: true,
          fullContent: true,
          narrow: true,
        },
      },
      {
        path: "/:locale([a-z]{2})/pages/:id",
        name: "page",
        props: true,
        component: () => import("@/views/PageView.vue"),
        meta: { title: ["$site"] },
      },
      {
        path: "/:locale([a-z]{2})/newsletter_abonnements/:accessToken",
        name: "newsletter_abonnements",
        props: true,
        component: () => import("@/views/NewsletterAbonnementsView.vue"),
      },
      {
        path: "/:locale([a-z]{2})/login",
        name: "login",
        props: true,
        component: () => import("@/views/SignInView.vue"),
        meta: {
          onlyLoggedOut: true,
          title: [".signIn"],
          narrow: true,
        },
      },
      {
        path: "/:locale([a-z]{2})/registration/:email?",
        name: "registration",
        props: (route) => ({
          group: route.query.group,
          email: route.params.email,
        }),
        component: () => import("@/views/RegistrationView.vue"),
        meta: {
          sidebar: false,
          title: [".accountRegistration"],
          narrow: true,
          linksNewWindow: true,
        },
      },
      {
        path: "/:locale([a-z]{2})/shibboleth_login_success",
        name: "shibboleth_login_success",
        component: () => import("@/views/ShibbolethLoginSuccessView.vue"),
      },
      {
        path: "/:locale([a-z]{2})/reset_password/:email?",
        name: "reset_password",
        props: true,
        component: () => import("@/views/ResetPasswordView.vue"),
        meta: { onlyLoggedOut: true, title: [".resetPassword"], narrow: true },
      },
      {
        path: "/:locale([a-z]{2})/access_token_login/:email?",
        name: "access_token_login",
        props: true,
        component: () => import("@/views/AccessTokenLoginView.vue"),
        meta: {
          sidebar: false,
          onlyLoggedOut: true,
          title: [".accessTokenLogin"],
          narrow: true,
        },
      },
      {
        path: "/:locale([a-z]{2})/my_account",
        name: "my_account_overview",
        component: () => import("@/views/my_account/OverviewView.vue"),
        meta: {
          loginRequired: true,
          title: ["myAccount", "SideBar.overview"],
        },
      },
      {
        path: `/:locale([a-z]{2})/my_account/${
          $settings.value.router_slugs!.events
        }`,
        name: "my_account_events",
        component: () => import("@/views/my_account/EventsView.vue"),
        meta: { loginRequired: true, title: ["myAccount", "SideBar.events"] },
      },
      {
        path: `/:locale([a-z]{2})/my_account/${
          $settings.value.router_slugs!.events
        }/:id`,
        name: "my_account_event",
        props: (route) => {
          return {
            id: route.params.id,
            editView: false,
          }
        },
        component: () => import("@/views/my_account/EventView.vue"),
        meta: {
          loginRequired: true,
          title: ["myAccount", "SideBar.events", "$site"],
        },
      },
      {
        path: `/:locale([a-z]{2})/my_account/${
          $settings.value.router_slugs!.events
        }/:id/edit`,
        name: "my_account_event_edit",
        props: true,
        component: () => import("@/views/my_account/EventView.vue"),
        meta: {
          loginRequired: true,
          title: ["myAccount", "SideBar.events", "$site", ".edit"],
        },
      },
      {
        path: `/:locale([a-z]{2})/my_account/${
          $settings.value.router_slugs!.events
        }/:event_id/mail_tasks/:id`,
        name: "my_account_event_mail_task",
        props: (route) => {
          return {
            id: route.params.id,
            resourceId: route.params.event_id,
            resourceType: "Event",
            backLocationParams: {
              id: route.params.event_id,
            },
          }
        },
        component: () => import("@/views/my_account/MailTaskView.vue"),
        meta: { loginRequired: true },
      },
      {
        path: "/:locale([a-z]{2})/my_account/newsletters",
        name: "my_account_newsletters",
        component: () => import("@/views/my_account/NewslettersView.vue"),
        meta: {
          loginRequired: true,
          title: ["myAccount", "SideBar.newsletters"],
        },
      },
      {
        path: "/:locale([a-z]{2})/my_account/newsletters/:id",
        name: "my_account_newsletter",
        props: true,
        component: () => import("@/views/my_account/NewsletterView.vue"),
        meta: {
          loginRequired: true,
          title: ["myAccount", "SideBar.newsletters", "$site"],
        },
      },
      {
        path: "/:locale([a-z]{2})/my_account/newsletters/:id/edit",
        name: "my_account_newsletter_edit",
        props: true,
        component: () => import("@/views/my_account/NewsletterEditView.vue"),
        meta: {
          loginRequired: true,
          title: ["myAccount", "SideBar.newsletters", "$site", ".edit"],
        },
      },
      {
        path: `/:locale([a-z]{2})/my_account/${
          $settings.value.router_slugs!.events
        }/event_applications/:id/edit`,
        name: "my_account_event_application_edit",
        props: (route) => {
          return {
            id: route.params.id,
            editView: true,
          }
        },
        component: () => import("@/views/my_account/EventApplicationView.vue"),
        meta: { loginRequired: true },
      },
      {
        path: `/:locale([a-z]{2})/my_account/${
          $settings.value.router_slugs!.events
        }/event_applications/:id`,
        name: "my_account_event_application",
        props: true,
        component: () => import("@/views/my_account/EventApplicationView.vue"),
        meta: {
          loginRequired: true,
          title: [
            "myAccount",
            "SideBar.events",
            "$event",
            "eventApplication",
            "$site",
          ],
        },
      },
      {
        path: `/:locale([a-z]{2})/my_account/${
          $settings.value.router_slugs!.events
        }/:event_id/invoices/:id`,
        name: "my_account_event_invoice",
        props: (route) => {
          return {
            id: route.params.id,
            backLocationName: "my_account_event",
            backLocationParams: { id: route.params.event_id },
          }
        },
        component: () => import("@/views/my_account/InvoiceView.vue"),
        meta: {
          loginRequired: true,
          title: [
            "myAccount",
            "SideBar.events",
            "$event",
            "SideBar.invoices",
            "$site",
          ],
        },
      },
      {
        path: "/:locale([a-z]{2})/my_account/event_series",
        name: "my_account_event_series_index",
        component: () => import("@/views/my_account/EventSeriesIndexView.vue"),
        meta: {
          loginRequired: true,
          title: ["myAccount", "SideBar.eventSeries"],
        },
      },
      {
        path: "/:locale([a-z]{2})/my_account/event_series/:id/edit",
        name: "my_account_event_series_edit",
        props: true,
        component: () => import("@/views/my_account/EventSeriesView.vue"),
        meta: {
          loginRequired: true,
          title: ["myAccount", "SideBar.eventSeries", "$site", ".edit"],
        },
      },
      {
        path: "/:locale([a-z]{2})/my_account/event_series/:id",
        name: "my_account_event_series",
        props: (route) => {
          return {
            id: route.params.id,
            editView: false,
          }
        },
        component: () => import("@/views/my_account/EventSeriesView.vue"),
        meta: {
          loginRequired: true,
          title: ["myAccount", "SideBar.eventSeries", "$site"],
        },
      },
      {
        path: "/:locale([a-z]{2})/my_account/polls",
        name: "my_account_polls",
        component: () => import("@/views/my_account/PollsView.vue"),
        meta: { loginRequired: true, title: ["myAccount", "SideBar.polls"] },
      },
      {
        path: "/:locale([a-z]{2})/my_account/polls/:id",
        name: "my_account_poll",
        props: (route) => {
          return {
            id: route.params.id,
            editView: false,
          }
        },
        component: () => import("@/views/my_account/PollView.vue"),
        meta: {
          loginRequired: true,
          title: ["myAccount", "SideBar.polls", "$site"],
        },
      },
      {
        path: "/:locale([a-z]{2})/my_account/polls/:id/edit",
        name: "my_account_poll_edit",
        props: true,
        component: () => import("@/views/my_account/PollView.vue"),
        meta: {
          loginRequired: true,
          title: ["myAccount", "SideBar.polls", "$site", ".edit"],
        },
      },
      {
        path: "/:locale([a-z]{2})/my_account/polls/poll_votes/:id",
        name: "my_account_poll_vote",
        props: (route) => {
          return {
            id: route.params.id,
            editView: false,
          }
        },
        component: () => import("@/views/my_account/PollVoteView.vue"),
        meta: {
          loginRequired: true,
        },
      },
      {
        path: "/:locale([a-z]{2})/my_account/mail_tasks",
        name: "my_account_mail_tasks",
        component: () => import("@/views/my_account/MailTasksView.vue"),
        meta: {
          loginRequired: true,
          title: ["myAccount", "SideBar.mailArchive"],
        },
      },
      {
        path: "/:locale([a-z]{2})/my_account/mail_tasks/:id",
        name: "my_account_mail_task",
        props: (route) => {
          return {
            id: route.params.id,
            backLocationName: "my_account_mail_tasks",
          }
        },
        component: () => import("@/views/my_account/MailTaskView.vue"),
        meta: {
          loginRequired: true,
          title: ["myAccount", "SideBar.mailArchive", "$site"],
        },
      },
      {
        path: "/:locale([a-z]{2})/my_account/users",
        name: "my_account_users",
        component: () => import("@/views/my_account/UsersView.vue"),
        meta: { loginRequired: true, title: ["myAccount", "SideBar.users"] },
      },
      {
        path: "/:locale([a-z]{2})/my_account/users/:id/edit",
        name: "my_account_user_edit",
        props: true,
        component: () => import("@/views/my_account/UserView.vue"),
        meta: {
          loginRequired: true,
          title: ["myAccount", "SideBar.users", "$site", ".edit"],
        },
      },
      {
        path: "/:locale([a-z]{2})/my_account/users/:id/add_new_email",
        name: "my_account_user_add_new_email",
        props: (route) => ({
          id: route.params.id,
          changeLogin: !!route.query.change_login,
        }),
        component: () => import("@/views/my_account/AddNewEmailView.vue"),
        meta: { loginRequired: true, sidebar: false },
      },
      {
        path: "/:locale([a-z]{2})/my_account/users/:user_id/invoices/:id",
        name: "my_account_user_invoice",
        props: (route) => {
          return {
            id: route.params.id,
            backLocationName: "my_account_user",
            backLocationParams: { id: route.params.user_id },
          }
        },
        component: () => import("@/views/my_account/InvoiceView.vue"),
        meta: {
          loginRequired: true,
          title: [
            "myAccount",
            "SideBar.users",
            "$user",
            "SideBar.invoices",
            "$site",
          ],
        },
      },
      {
        path: "/:locale([a-z]{2})/my_account/users/:id",
        name: "my_account_user",
        props: (route) => {
          return {
            id: route.params.id,
            editView: false,
          }
        },
        component: () => import("@/views/my_account/UserView.vue"),
        meta: {
          loginRequired: true,
          title: ["myAccount", "SideBar.users", "$site"],
        },
      },
      {
        path: "/:locale([a-z]{2})/my_account/users/:user_id/event_applications/:id",
        name: "my_account_user_event_application",
        props: (route) => {
          return {
            id: route.params.id,
            backLocationName: "my_account_user",
            backLocationParams: {
              id: route.params.user_id,
            },
          }
        },
        component: () => import("@/views/my_account/EventApplicationView.vue"),
        meta: { loginRequired: true },
      },
      {
        path: "/:locale([a-z]{2})/my_account/event-schedules",
        name: "my_account_event_schedule",
        component: () => import("@/views/my_account/EventScheduleView.vue"),
        meta: { loginRequired: true },
      },
      {
        path: `/:locale([a-z]{2})/my_account/event-schedules/${
          $settings.value.router_slugs!.event
        }/:id`,
        name: "my_account_event_schedule_event",
        props: (route) => {
          return {
            id: route.params.id,
            backLocationName: "my_account_event_schedule",
            editLocationName: "my_account_event_schedule_event_edit",
            editView: false,
          }
        },
        component: () => import("@/views/my_account/EventView.vue"),
        meta: { loginRequired: true },
      },
      {
        path: `/:locale([a-z]{2})/my_account/event-schedules/${
          $settings.value.router_slugs!.event
        }/:id/edit`,
        name: "my_account_event_schedule_event_edit",
        props: (route) => {
          return {
            id: route.params.id,
            backLocationName: "my_account_event_schedule",
            showLocationName: "my_account_event_schedule_event",
          }
        },
        component: () => import("@/views/my_account/EventView.vue"),
        meta: { loginRequired: true },
      },
      {
        path: `/:locale([a-z]{2})/my_account/${
          $settings.value.router_slugs!.my_events
        }/:id`,
        name: "my_account_my_event",
        props: (route) => {
          return {
            id: route.params.id,
            backLocationName: "my_account_my_events",
          }
        },
        component: () => import("@/views/EventView.vue"),
        meta: {
          loginRequired: true,
          title: ["myAccount", "SideBar.events", "$site"],
        },
      },
      {
        path: `/:locale([a-z]{2})/my_account/${
          $settings.value.router_slugs!.my_events
        }`,
        name: "my_account_my_events",
        component: () => import("@/views/my_account/MyEventsView.vue"),
        meta: { loginRequired: true, title: ["myAccount", "SideBar.events"] },
      },
      {
        path: "/:locale([a-z]{2})/my_account/my_invoices",
        name: "my_account_my_invoices",
        component: () => import("@/views/my_account/InvoicesView.vue"),
        props: () => {
          return {
            withStateChange: false,
            showLocationName: null,
            showSearch: false,
            withUser: false,
            showEventLocationName: "my_account_my_event",
            scopeFilter: { user_id: $login.value?.user?.id },
          }
        },
        meta: { loginRequired: true, title: ["myAccount", "SideBar.invoices"] },
      },
      {
        path: "/:locale([a-z]{2})/my_account/my_news",
        name: "my_account_my_news",
        component: () => import("@/views/my_account/MyNewsView.vue"),
        meta: { loginRequired: true },
      },
      {
        path: "/:locale([a-z]{2})/my_account/complete_user",
        name: "my_account_complete_user",
        component: () => import("@/views/my_account/CompleteUserView.vue"),
        meta: { loginRequired: true, sidebar: false },
      },
      {
        path: "/:locale([a-z]{2})/my_account/confirm_data",
        name: "my_account_confirm_data",
        component: () => import("@/views/my_account/ConfirmDataView.vue"),
        meta: { loginRequired: true, sidebar: false },
      },
      {
        path: "/:locale([a-z]{2})/my_account/administration",
        name: "my_account_administration",
        redirect: { name: "my_account_master_data" },
        meta: { loginRequired: true },
      },
      {
        path: "/:locale([a-z]{2})/my_account/administration/master_data",
        name: "my_account_master_data",
        component: () => import("@/views/my_account/MasterDataView.vue"),
        meta: {
          loginRequired: true,
          title: ["myAccount", "SideBar.administration", "SideBar.master_data"],
        },
      },
      {
        path: "/:locale([a-z]{2})/my_account/administration/team",
        name: "my_account_team",
        component: () => import("@/views/my_account/TeamView.vue"),
        meta: {
          loginRequired: true,
          title: ["myAccount", "SideBar.administration", "SideBar.team"],
        },
      },
      {
        path: "/:locale([a-z]{2})/my_account/team/:id",
        name: "my_account_team_user",
        props: (route) => {
          return {
            id: route.params.id,
            backLocationName: "my_account_team",
            editLocationName: "my_account_team_user_edit",
            showLocationName: "my_account_team_user",
            editView: false,
          }
        },
        component: () => import("@/views/my_account/UserView.vue"),
        meta: {
          loginRequired: true,
          title: [
            "myAccount",
            "SideBar.administration",
            "SideBar.team",
            "$site",
          ],
        },
      },
      {
        path: "/:locale([a-z]{2})/my_account/team/:id/edit",
        name: "my_account_team_user_edit",
        props: (route) => {
          return {
            id: route.params.id,
            backLocationName: "my_account_team",
            editLocationName: "my_account_team_user_edit",
            showLocationName: "my_account_team_user",
            editView: true,
          }
        },
        component: () => import("@/views/my_account/UserView.vue"),
        meta: {
          loginRequired: true,
          title: [
            "myAccount",
            "SideBar.administration",
            "SideBar.team",
            "$site",
            ".edit",
          ],
        },
      },
      {
        path: "/:locale([a-z]{2})/my_account/administration/pages",
        name: "my_account_pages",
        component: () => import("@/views/my_account/PagesView.vue"),
        meta: {
          loginRequired: true,
          title: ["myAccount", "SideBar.administration", "SideBar.pages"],
        },
      },
      {
        path: "/:locale([a-z]{2})/my_account/administration/pages/:id/edit",
        name: "my_account_page_edit",
        props: true,
        component: () => import("@/views/my_account/PageEditView.vue"),
        meta: { loginRequired: true },
      },
      {
        path: "/:locale([a-z]{2})/my_account/invoices/:id",
        name: "my_account_invoice",
        props: (route) => {
          return {
            id: route.params.id,
          }
        },
        component: () => import("@/views/my_account/InvoiceView.vue"),
        meta: {
          loginRequired: true,
          title: ["myAccount", "SideBar.invoices", "$site"],
        },
      },
      {
        path: "/:locale([a-z]{2})/my_account/invoices",
        name: "my_account_invoices",
        component: () => import("@/views/my_account/InvoicesView.vue"),
        meta: {
          loginRequired: true,
          title: ["myAccount", "SideBar.invoices"],
        },
      },
      {
        path: "/:locale([a-z]{2})/my_account/news_entries/:id",
        name: "my_account_news_entry",
        props: (route) => {
          return {
            id: route.params.id,
            editView: false,
          }
        },
        component: () => import("@/views/my_account/NewsEntryView.vue"),
        meta: { loginRequired: true },
      },
      {
        path: "/:locale([a-z]{2})/my_account/news_entries/:id/edit",
        name: "my_account_news_entry_edit",
        props: true,
        component: () => import("@/views/my_account/NewsEntryView.vue"),
        meta: { loginRequired: true },
      },
      {
        path: "/:locale([a-z]{2})/my_account/news_entries",
        name: "my_account_news_entries",
        component: () => import("@/views/my_account/NewsEntriesView.vue"),
        meta: {
          loginRequired: true,
          title: ["myAccount", "SideBar.administration", "SideBar.newsEntries"],
        },
      },
      {
        path: "/:locale([a-z]{2})/my_account/administration/tags/:id",
        name: "my_account_tag",
        props: (route) => {
          return {
            id: route.params.id,
            editView: false,
          }
        },
        component: () => import("@/views/my_account/TagView.vue"),
        meta: { loginRequired: true },
      },
      {
        path: "/:locale([a-z]{2})/my_account/administration/tags/:id/edit",
        name: "my_account_tag_edit",
        props: true,
        component: () => import("@/views/my_account/TagView.vue"),
        meta: { loginRequired: true },
      },
      {
        path: "/:locale([a-z]{2})/my_account/administration/tags",
        name: "my_account_tags",
        component: () => import("@/views/my_account/TagsView.vue"),
        meta: {
          loginRequired: true,
          title: ["myAccount", "SideBar.administration", "SideBar.tags"],
        },
      },
      {
        path: "/:locale([a-z]{2})/my_account/administration/mail_templates",
        name: "my_account_mail_templates",
        component: () => import("@/views/my_account/MailTemplatesView.vue"),
        meta: {
          loginRequired: true,
          title: [
            "myAccount",
            "SideBar.administration",
            "SideBar.mail_templates",
          ],
        },
      },
      {
        path: "/:locale([a-z]{2})/my_account/administration/mail_templates/:id/edit",
        name: "my_account_mail_template_edit",
        props: true,
        component: () => import("@/views/my_account/MailTemplateEditView.vue"),
        meta: { loginRequired: true },
      },
      {
        path: "/:locale([a-z]{2})/my_account/administration/forms",
        name: "my_account_forms",
        component: () => import("@/views/my_account/FormsView.vue"),
        meta: {
          loginRequired: true,
          title: ["myAccount", "SideBar.administration", "SideBar.forms"],
        },
      },
      {
        path: "/:locale([a-z]{2})/my_account/administration/forms/:id/edit",
        name: "my_account_form_edit",
        props: true,
        component: () => import("@/views/my_account/FormEditView.vue"),
        meta: { loginRequired: true },
      },
      {
        path: "/:locale([a-z]{2})/my_account/administration/polls",
        name: "my_account_administration_polls",
        component: () => import("@/views/my_account/PollGlobalConfigView.vue"),
        meta: {
          loginRequired: true,
          title: ["myAccount", "SideBar.administration", "SideBar.polls"],
        },
      },
      {
        path: "/:locale([a-z]{2})/my_account/administration/polls/forms/:id/edit",
        name: "my_account_administration_polls_form_edit",
        props: true,
        component: () =>
          import("@/views/my_account/PollGlobalConfigFormView.vue"),
        meta: { loginRequired: true },
      },
      {
        path: "/:locale([a-z]{2})/my_account/administration/certificates",
        name: "my_account_administration_certificates",
        component: () =>
          import("@/views/my_account/CertificateGlobalConfigView.vue"),
        meta: {
          loginRequired: true,
          title: [
            "myAccount",
            "SideBar.administration",
            "SideBar.certificates",
          ],
        },
      },
      {
        path: "/:locale([a-z]{2})/my_account/administration/certificates/pdf_images/:id",
        name: "my_account_administration_certificate_pdf_image_edit",
        props: true,
        component: () => import("@/views/my_account/PdfImageEditView.vue"),
        meta: {
          loginRequired: true,
          title: [
            "myAccount",
            "SideBar.administration",
            "SideBar.certificates",
          ],
        },
      },
      {
        path: "/:locale([a-z]{2})/my_account/administration/invoices",
        name: "my_account_administration_invoices",
        component: () =>
          import("@/views/my_account/InvoiceGlobalConfigView.vue"),
        meta: {
          loginRequired: true,
          title: ["myAccount", "SideBar.administration", "SideBar.invoices"],
        },
      },
      {
        path: "/:locale([a-z]{2})/my_account/administration/invoices/pdf_layouts/:id",
        name: "my_account_administration_invoice_pdf_layout_edit",
        props: (route) => {
          return {
            id: route.params.id,
            backLocationName: "my_account_administration_invoices",
          }
        },
        component: () => import("@/views/my_account/PdfLayoutEditView.vue"),
        meta: {
          loginRequired: true,
          title: ["myAccount", "SideBar.administration", "SideBar.invoices"],
        },
      },
      {
        path: "/:locale([a-z]{2})/my_account/administration/invoices/pdf_images/:id",
        name: "my_account_administration_invoice_pdf_image_edit",
        props: (route) => {
          return {
            id: route.params.id,
            backLocationName: "my_account_administration_invoices",
          }
        },
        component: () => import("@/views/my_account/PdfImageEditView.vue"),
        meta: {
          loginRequired: true,
          title: [
            "myAccount",
            "SideBar.administration",
            "SideBar.certificates",
          ],
        },
      },
      {
        path: "/:locale([a-z]{2})/my_account/administration/pdf_layouts/:id",
        name: "my_account_administration_pdf_layout_edit",
        props: true,
        component: () => import("@/views/my_account/PdfLayoutEditView.vue"),
        meta: {
          loginRequired: true,
          title: [
            "myAccount",
            "SideBar.administration",
            "SideBar.certificates",
          ],
        },
      },
      {
        path: "/:locale([a-z]{2})/my_account/administration/form_inputs/:id",
        name: "my_account_administration_form_input_edit",
        props: true,
        component: () => import("@/views/my_account/FormInputEditView.vue"),
        meta: {
          loginRequired: true,
          title: ["myAccount", "SideBar.administration", "SideBar.polls"],
        },
      },
      {
        path: "/:locale([a-z]{2})/my_account/administration/log",
        name: "my_account_log_entries",
        component: () => import("@/views/my_account/LogEntriesView.vue"),
        meta: {
          loginRequired: true,
          title: ["myAccount", "SideBar.administration", "SideBar.logEntries"],
        },
      },
      {
        path: "/:locale([a-z]{2})/my_account/administration/signatures",
        name: "my_account_signatures",
        component: () => import("@/views/my_account/SignaturesView.vue"),
        meta: {
          loginRequired: true,
          title: ["myAccount", "SideBar.administration", "SideBar.signatures"],
        },
      },
      {
        path: "/:locale([a-z]{2})/my_account/administration/signatures/:id/edit",
        name: "my_account_signature_edit",
        props: true,
        component: () => import("@/views/my_account/SignatureEditView.vue"),
        meta: { loginRequired: true },
      },
      {
        path: "/:locale([a-z]{2})/my_account/administration/universities",
        name: "my_account_universities",
        component: () => import("@/views/my_account/UniversitiesView.vue"),
        meta: {
          loginRequired: true,
          title: [
            "myAccount",
            "SideBar.administration",
            "SideBar.universities",
          ],
        },
      },
      {
        path: "/:locale([a-z]{2})/my_account/administration/universities/:id/edit",
        name: "my_account_university_edit",
        props: true,
        component: () => import("@/views/my_account/UniversityView.vue"),
        meta: { loginRequired: true },
      },
      {
        path: "/:locale([a-z]{2})/my_account/administration/universities/:id",
        name: "my_account_university",
        props: (route) => {
          return {
            id: route.params.id,
            editView: false,
          }
        },
        component: () => import("@/views/my_account/UniversityView.vue"),
        meta: { loginRequired: true },
      },
      {
        path: "/:locale([a-z]{2})/admin",
        name: "admin",
        component: () => import("@/views/admin/OverviewView.vue"),
        meta: { loginRequired: true, adminArea: true },
      },
      {
        path: "/:locale([a-z]{2})/admin/settings",
        name: "admin_settings",
        component: () => import("@/views/admin/SettingsView.vue"),
        meta: { loginRequired: true, adminArea: true },
      },
      {
        path: "/:locale([a-z]{2})/admin/branding",
        name: "admin_branding",
        component: () => import("@/views/admin/BrandingView.vue"),
        meta: { loginRequired: true, adminArea: true },
      },
      {
        path: "/:locale([a-z]{2})/admin/smtp_configs",
        name: "admin_smtp_configs",
        component: () => import("@/views/admin/SmtpConfigsView.vue"),
        meta: { loginRequired: true, adminArea: true },
      },
      {
        path: "/:locale([a-z]{2})/admin/smtp_configs/:id/edit",
        name: "admin_smtp_config_edit",
        props: true,
        component: () => import("@/views/admin/SmtpConfigEditView.vue"),
        meta: { loginRequired: true, adminArea: true },
      },
      {
        path: "/:locale([a-z]{2})/admin/main_categories/:id",
        name: "admin_main_category",
        props: (route) => {
          return {
            id: route.params.id,
            editView: false,
          }
        },
        component: () => import("@/views/admin/MainCategoryView.vue"),
        meta: { loginRequired: true, adminArea: true },
      },
      {
        path: "/:locale([a-z]{2})/admin/main_categories/:id/edit",
        name: "admin_main_category_edit",
        props: true,
        component: () => import("@/views/admin/MainCategoryView.vue"),
        meta: { loginRequired: true, adminArea: true },
      },
      {
        path: "/:locale([a-z]{2})/admin/main_categories",
        name: "admin_main_categories",
        component: () => import("@/views/admin/MainCategoriesView.vue"),
        meta: { loginRequired: true, adminArea: true },
      },
      {
        path: "/:locale([a-z]{2})/admin/smtp_email_addresses/:id/edit",
        name: "admin_smtp_email_address_edit",
        props: true,
        component: () => import("@/views/admin/SmtpEmailAddressEditView.vue"),
        meta: { loginRequired: true, adminArea: true },
      },
      {
        path: "/:locale([a-z]{2})/admin/domains",
        name: "admin_domains",
        component: () => import("@/views/admin/DomainsView.vue"),
        meta: { loginRequired: true, adminArea: true },
      },
      {
        path: "/:locale([a-z]{2})/admin/domains/:id/edit",
        name: "admin_domain_edit",
        props: true,
        component: () => import("@/views/admin/DomainView.vue"),
        meta: { loginRequired: true, adminArea: true },
      },
      {
        path: "/:locale([a-z]{2})/admin/places",
        name: "admin_places",
        component: () => import("@/views/admin/PlacesView.vue"),
        meta: { loginRequired: true, adminArea: true },
      },
      {
        path: "/:locale([a-z]{2})/admin/places/:id/edit",
        name: "admin_place_edit",
        props: true,
        component: () => import("@/views/admin/PlaceEditView.vue"),
        meta: { loginRequired: true, adminArea: true },
      },
      {
        path: "/:locale([a-z]{2})/admin/forms",
        name: "admin_forms",
        component: () => import("@/views/admin/FormsView.vue"),
        meta: { loginRequired: true, adminArea: true },
      },
      {
        path: "/:locale([a-z]{2})/admin/forms/:id/edit",
        name: "admin_form_edit",
        props: true,
        component: () => import("@/views/admin/FormEditView.vue"),
        meta: { loginRequired: true, adminArea: true },
      },
      {
        path: "/:locale([a-z]{2})/admin/pdf_settings",
        name: "admin_pdf_settings",
        component: () => import("@/views/admin/PdfSettingsView.vue"),
        meta: { loginRequired: true, adminArea: true },
      },
      {
        path: "/:locale([a-z]{2})/admin/pdf_settings/:id/edit",
        name: "admin_pdf_setting_edit",
        props: true,
        component: () => import("@/views/admin/PdfSettingView.vue"),
        meta: { loginRequired: true, adminArea: true },
      },
      {
        path: "/:locale([a-z]{2})/admin/mail_templates",
        name: "admin_mail_templates",
        component: () => import("@/views/admin/MailTemplatesView.vue"),
        meta: { loginRequired: true, adminArea: true },
      },
      {
        path: "/:locale([a-z]{2})/admin/mail_templates/:id/edit",
        name: "admin_mail_template_edit",
        props: true,
        component: () => import("@/views/admin/MailTemplateView.vue"),
        meta: { loginRequired: true, adminArea: true },
      },
      {
        path: "/:locale([a-z]{2})/admin/translations",
        name: "admin_translations",
        component: () => import("@/views/admin/TranslationsView.vue"),
        meta: { loginRequired: true, adminArea: true },
      },
      {
        path: "/:locale([a-z]{2})/*",
        redirect: (to) => {
          return {
            name: loggedIn()
              ? "my_account_overview"
              : $settings.value.home?.name || "login",
            params: Object.assign({}, to.params, $settings.value.home?.params),
            query: to.query,
          }
        },
      },
      {
        path: "/:path",
        redirect: (to) => {
          return {
            path: `/${locales.default}${to.path}`,
            query: to.query,
          }
        },
      },
      {
        path: "*",
        redirect: (to) => {
          return {
            name: loggedIn()
              ? "my_account_overview"
              : $settings.value.home?.name || "login",
            params: Object.assign(
              {},
              { locale: locales.default },
              $settings.value.home?.params,
            ),
            query: to.query,
          }
        },
      },
    ],
  })

  router.onError((error) => {
    if (/loading chunk .* failed/i.test(error.message)) {
      window.location.reload()
    }
  })

  // hide sidebar
  router.beforeEach((_to, _from, next) => {
    $store.state.sidebar.hidden = true
    next()
  })

  // set locale
  router.beforeEach((to, _from, next) => {
    if (!to.params.locale || !locales.allowed.includes(to.params.locale)) {
      let newLocale = ""

      if (
        to.query.locale &&
        typeof to.query.locale == "string" &&
        locales.allowed.includes(to.query.locale)
      ) {
        newLocale = to.query.locale
      } else {
        newLocale = locales.default
      }

      const newParams = Object.assign({}, to.params, { locale: newLocale })
      const newQuery = Object.assign({}, to.query, {
        locale:
          to.meta?.localePath === false && newLocale != locales.default
            ? newLocale
            : undefined,
      })
      next({
        name: to.name || undefined,
        params: newParams,
        query: newQuery,
        replace: true,
      })
      return
    }

    if (to.query.locale && to.meta?.localePath !== false) {
      next({
        name: to.name || undefined,
        params: to.params,
        query: Object.assign({}, to.query, { locale: undefined }),
        replace: true,
      })
      return
    }

    setLocale(to.params.locale)
    next()
  })

  // redirect to login if route requires login
  router.beforeEach((to, from, next) => {
    if (
      to.matched.every((record) => !record.meta.loginRequired) ||
      loggedIn()
    ) {
      next()
      return
    }

    next({ name: "login", query: from.query })
  })

  // redirect to account if route disallows login
  router.beforeEach((to, from, next) => {
    if (
      to.matched.every((record) => !record.meta.onlyLoggedOut) ||
      !loggedIn()
    ) {
      next()
      return
    }

    next({ name: "my_account_overview", query: from.query })
  })

  // redirect away route needs admin
  router.beforeEach((to, _from, next) => {
    if (
      to.matched.every((record) => !record.meta.adminArea) ||
      $login.value?.global_role_names?.includes("stellenticket_admin")
    ) {
      next()
      return
    }

    next({ path: "/" })
  })

  // redirect to registration if account is not completely registered
  router.beforeEach((to, _from, next) => {
    if (
      $login.value &&
      to.name != "my_account_user_add_new_email" &&
      to.name != "registration" &&
      to.name != "page" &&
      $login.value?.user?.type === "AccountUser" &&
      !$login.value?.user?.confirmed
    ) {
      next({ name: "registration" })
      return
    }

    next()
  })

  // redirect to data confirmation if needed
  router.beforeEach((to, _from, next) => {
    if (
      $login.value &&
      to.name != "my_account_confirm_data" &&
      to.name != "my_account_user_add_new_email" &&
      to.name != "registration" &&
      to.name != "page" &&
      $login.value?.user?.type !== "GuestUser" &&
      $login.value?.user?.type !== "EventUser" &&
      $login.value?.user?.type !== "PollUser" &&
      $login.value?.user?.meta?.needs_data_confirmation
    ) {
      try {
        localStorage.setItem(
          "cng.afterLoginRoute",
          JSON.stringify({
            name: to.name,
            params: to.params,
            hash: to.hash,
          }),
        )
      } catch {
        // ignore
      }

      next({ name: "my_account_confirm_data" })
      return
    }

    next()
  })

  // redirect to complete user if account is not completely filled out
  router.beforeEach((to, _from, next) => {
    if (
      $login.value &&
      to.name != "my_account_complete_user" &&
      to.name != "my_account_confirm_data" &&
      to.name != "my_account_user_add_new_email" &&
      to.name != "registration" &&
      to.name != "page" &&
      $login.value.user?.type !== "GuestUser" &&
      $login.value.user?.type !== "EventUser" &&
      $login.value.user?.type !== "PollUser" &&
      $login.value.user?.meta &&
      !$login.value.user?.meta.filled_out_completely
    ) {
      try {
        localStorage.setItem(
          "cng.afterLoginRoute",
          JSON.stringify({
            name: to.name,
            params: to.params,
            hash: to.hash,
          }),
        )
      } catch {
        // ignore
      }

      next({ name: "my_account_complete_user" })
      return
    }

    next()
  })

  // edit newsletter abonnements if params are present
  router.beforeEach(async (to, _from, next) => {
    if (!to.query.be_newsletter_abonnements) {
      next()
      return
    }

    const accessToken = (to.query.be_newsletter_abonnements as any)
      ?.access_token as string
    delete to.query.be_newsletter_abonnements

    next({
      name: "newsletter_abonnements",
      params: {
        locale: to.params.locale || locales.default,
        accessToken,
      },
      replace: true,
    })
  })

  // view newsletter abonnements if params are present
  router.beforeEach(async (to, _from, next) => {
    if (!to.query.be_newsletter_view) {
      next()
      return
    }

    const accessToken = (to.query.be_newsletter_view as any)?.token as string
    delete to.query.be_newsletter_view

    next({
      name: "newsletter_view",
      params: {
        locale: to.params.locale || locales.default,
        accessToken,
      },
      hash: to.hash,
      replace: true,
    })
  })

  // redirect to reset password form
  router.beforeEach(async (to, _from, next) => {
    if (!to.query.be_reset_password) {
      next()
      return
    }

    const beParams: any = to.query.be_reset_password

    const email: any = beParams?.email
    const tokenKnown = !!beParams?.token_known

    next({
      name: "reset_password",
      params: {
        locale: to.params.locale || locales.default,
        tokenKnown: tokenKnown as any,
        email,
      },
      replace: true,
    })
  })

  // redirect to access token login form
  router.beforeEach(async (to, _from, next) => {
    if (!to.query.be_access_token_login) {
      next()
      return
    }

    const beParams: any = to.query.be_access_token_login

    const email: any = beParams?.email

    next({
      name: "access_token_login",
      params: {
        locale: to.params.locale || locales.default,
        email,
      },
      replace: true,
    })
  })

  return router
}
