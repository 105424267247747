import ResourceApi from "./resource_api"

import type EventSeries from "@/models/EventSeries"

type curResourceProperties = ResourcePropertiesOf<EventSeries>

export default class EventSerisApi extends ResourceApi<curResourceProperties> {
  namespace = "event_series"
  type = "event_series"

  async publish(id: string): Promise<curResourceProperties> {
    const res = await this.axios.put(this.apiPath({ scope: `${id}/publish` }), {
      data: {
        id,
        type: this.type,
      },
    })

    return this.serializer.deserialize(res.data) as curResourceProperties
  }

  async unpublish(id: string): Promise<curResourceProperties> {
    const res = await this.axios.put(
      this.apiPath({ scope: `${id}/unpublish` }),
      {
        data: {
          id,
          type: this.type,
        },
      },
    )

    return this.serializer.deserialize(res.data) as curResourceProperties
  }
}
