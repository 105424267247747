import { computed, onMounted } from "vue"

import { useStore } from "@/store"

import { type State as FormsState } from "@/store/forms"

export const $forms = computed(() => {
  return useStore().state.forms.forms
})

export const $formPromises = computed(() => {
  return useStore().state.forms.promises
})

export const needsForms = <T extends keyof FormsState["forms"]>(forms: T[]) => {
  onMounted(() => {
    forms.forEach((name: string) => {
      useStore().dispatch("forms/fetchForm", name)
    })
  })

  return computed(() => useStore().state.forms as Pick<FormsState["forms"], T>)
}

export default {
  install: (Vue: any) => {
    Object.defineProperty(Vue.prototype, "$forms", {
      get() {
        return $forms.value
      },
    })

    Object.defineProperty(Vue.prototype, "$formPromises", {
      get() {
        return $formPromises.value
      },
    })

    Vue.mixin({
      beforeCreate() {
        if (this.$options.forms) {
          this.$options.forms.forEach((name: string) => {
            useStore().dispatch("forms/fetchForm", name)
          })
        }
      },
    })
  },
}
