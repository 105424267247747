import axios from "@/axios"
import { serialize as objectToFormData } from "object-to-formdata"

import { State as SettingsState } from "@/store/settings"

export default {
  async get(keys: Array<keyof SettingsState>): Promise<SettingsState> {
    const res = await axios.get("settings", { params: { keys } })
    return res.data
  },

  async getAttachment(key: keyof SettingsState): Promise<FileResource> {
    const res = await axios.get(`settings/${key}/attachment`)
    return res.data || null
  },

  async getAttachments(
    keys: Array<keyof SettingsState>,
  ): Promise<FileResource[]> {
    const res = await axios.get(`settings/${keys[0]}/attachment`, {
      params: { data: { keys } },
    })
    return res.data || null
  },

  async update(key: keyof SettingsState, value: any) {
    const res = await axios.put(`settings/${key}`, { value })
    return res.data
  },

  async updateAttachment(
    key: keyof SettingsState,
    value: unknown,
  ): Promise<FileResource> {
    value ??= null

    const res = await axios.put(
      `settings/${key}/attachment`,
      objectToFormData({ value }),
    )
    return res.data
  },
}
